@import '../../../styles/main.scss';

.container {
  box-sizing: border-box;
  flex-direction: column;
  padding: $universalSize-56 $universalSize-60 $universalSize-20 $universalSize-60;
  margin: auto;
  max-width: $universalSize-800;
  @include respond(x-large) {
    padding: $universalSize-72 $universalSize-40 $universalSize-20 $universalSize-40;
  }

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-20 $universalSize-20 $universalSize-20;
  }

  .headingWrapper {
    .heading {
      font-size: $universalSize-46;
      font-weight: $font-weight-section-heading;
      margin-bottom: $universalSize-10;
      color: $dark-blue-color;

      @include respond(medium) {
        font-size: $font-size-28;
      }
    }
    .subTitle {
      font-size: $font-size-20;
      margin: $universalSize-16 0;
      color: $blue-color-600;

      @include respond(medium) {
        font-size: $universalSize-16;
      }
    }

    .authorWrapper {
      display: flex;
      gap: $universalSize-4;
      padding-top: $universalSize-16;
      margin-bottom: $universalSize-15;
      .authorData {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond(medium) {
          align-items: flex-start;
          flex-direction: column;
          gap: $universalSize-12;
        }
        .authorImage {
          position: relative;
          width: $universalSize-56;
          height: $universalSize-56;
          border-radius: $universalSize-44$universalSize-48;
          overflow: hidden;
          @include respond(medium) {
            width: $universalSize-48;
            height: $universalSize-48;
          }
        }
      }
      .authorDetails {
        display: flex;
        flex-direction: column;
        gap: $universalSize-8;
        .authorName {
          text-decoration: none;
          color: $dark-purple-color;
          font-size: $universalSize-16;
        }
        .subHeading {
          display: flex;
          font-size: $font-size-14;
          color: $black-color-a-50;
          font-weight: $font-weight-500;
          gap: $universalSize-4;
          align-items: center;
          @include respond(medium) {
            font-size: $font-size-12;
          }
        }
      }
    }
  }
}
