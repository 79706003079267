@import '../../../../styles/main.scss';

.heroImageBeforeToc {

    p {
      font-size: $universalSize-16;
      line-height: $universalSize-26;
      font-weight: $font-weight-para-heading;
      word-break: break-word;
      margin: $universalSize-28 0;

      @include respond(small) {
        font-size: $universalSize-16;
        line-height: $line-height-small;
      }
    }
  
    img {
      width: 100%;
      height: auto;
    
      @include respond(small) {
        width: 90vw;
        height: auto;
      }
    }
  }