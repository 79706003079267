@import '../../../../styles/main.scss';

.tagContainer {
    display: flex;
    padding: $universalSize-20 0;
    box-sizing: border-box;
    background: $white-color;
    flex-wrap: wrap;
  
    @media screen and (min-width: 1141px) {
      padding: 0;
    }
    .tagText{
        font-size: $font-size-14;
        margin-left: $universalSize-5;
        color: $dark-blue-color;
    }

    .tagitem {
        display: flex;
        font-size: $font-size-14;
        font-weight: $font-weight-para-heading;
        color: $text-color-5;
        background: $purple-color-10;
        border-radius: $universalSize-4;
        width: auto;
        margin-right: $universalSize-5$universalSize-5;
        margin-left: $universalSize-5;
        padding: $universalSize-5;
        margin-bottom: $universalSize-5;
      
        @media screen and (min-width: 426px) {
          font-size: $font-size-14;
        }

    }
}