@import '../../../styles/main.scss';
.footerWrapperContainer {
  width: 100%;
  position: relative;
  padding-bottom: $universalSize-48;
  @include respond(medium) {
    padding: 0 $universalSize-28 $universalSize-32 $universalSize-28;
  }
  .footerWrapper {
    width: 100%;
    max-width: $universalSize-1320;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid $surface-light-grey;
    border-bottom: 1px solid $surface-light-grey;
    @include respond(medium) {
      flex-direction: column;
      gap: $universalSize-20;
    }
    .brandInfo {
      display: flex;
      flex-direction: column;
      padding: $universalSize-28;
      gap: $universalSize-12;

      @include respond(medium) {
        flex-direction: row;
        align-items: center;
        gap: $universalSize-8;
        padding: $universalSize-8 $universalSize-4;
      }
      .brandName {
        @include desktopHeading4();

        @include respond(medium) {
          align-self: center;
          flex: 2 1 0;
        }
      }
      .addressAndSocialMediaWrapper {
        display: flex;
        flex-direction: column;
        gap: $universalSize-8;
        .brandAddress {
          font-size: $universalSize-12;
          line-height: $universalSize-18;
          color: $grey-color-800;
          max-width: $universalSize-280;
          @include respond(medium) {
          }
        }
        @include respond(medium) {
          flex: 3 1 0;
        }
        .socialMediaContainer {
          display: flex;
          gap: $universalSize-12;
        }
      }
    }
    .footerContainer {
      position: absolute;
      @include absolutePositionHorizontalCenter();
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: $universalSize-28;
      .footerItem {
        text-decoration: none;
        color: $blue-color-600;
        padding: $universalSize-8 $universalSize-4;
        cursor: pointer;
      }
      @include respond(medium) {
        position: static;
        transform: none;
      }
    }
  }
}
