@import '../../../styles/main.scss';

.cardContainerBox {
  display: flex;
  gap: $universalSize-12;
  cursor: pointer;

  &.isVideoListPage {
    gap: $universalSize-16;

    &:not(&.isMobile) {
      flex-direction: column;
      border: $universalSize-1 solid $white-color-border;
      border-radius: $universalSize-12;
      padding: $universalSize-16;
      margin: $universalSize-20 0;
    }
  }
  &.isMobile {
    &:not(&.isVideoListPage) {
      flex-direction: column;
    }
  }
  &.isVideoListPage,
  &:not(&.isMobile) {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);

      &.isMobile {
        border-bottom: $universalSize-1 solid rgb(224, 224, 232);
      }
      padding: $universalSize-20 0;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-top: $universalSize-20;
    }
  }
  .imageContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .thumbnail {
      border-radius: $universalSize-8;

      width: $universalSize-108;
      height: $universalSize-72;
      &.isMobile {
        &:not(.isVideoListPage) {
          width: $universalSize-210;
          height: $universalSize-118;
        }
      }
      &:not(.isMobile) {
        &.isVideoListPage {
          width: $universalSize-240;
          height: $universalSize-150;
        }
      }
    }
    .overlay {
      width: $universalSize-36;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:not(.isMobile) {
        &.isVideoListPage {
          width: $universalSize-48;
        }
      }
    }
    .overlayPlaying {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $universalSize-2 0;
    width: 100%;

    // &:not(.isMobile) {
    &.isVideoListPage,
    &.isMobile {
      width: $universalSize-210;

      justify-content: space-between;
      height: $universalSize-70;
    }
    // }
    .title {
      color: $white-color;
      width: $universalSize-235;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $universalSize-14;
      font-weight: $font-weight-card-heading;
      line-height: $universalSize-20;
      margin: 0;
      &.isVideoListPage {
        color: $dark-purple-color;
      }
      @include respond(medium) {
        width: auto;
      }
    }
    .details {
      color: $white-color;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: $universalSize-14;
      font-weight: $font-weight-para-heading;
      line-height: $universalSize-20;
      .dotOrPipe {
        display: none;
      }
      .duration {
        display: none;
      }

      &.isVideoListPage {
        color: $purple-variant-2;
      }
      &.isVideoListPage,
      &:not(.isMobile) {
        .dotOrPipe {
          display: block;
          margin: 0 $universalSize-8;

          @include respond(medium) {
            margin: 0 $universalSize-16;
          }
        }
        .duration {
          display: block;
        }
      }
    }
  }
}
