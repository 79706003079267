@import '../../../styles/main.scss';
.brandHeaderContainer {
  width: 100%;

  .brandHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    .heading {
      @include desktopHeading3();
      align-self: center;
      padding: $universalSize-4 $universalSize-16;
      @include respond(medium) {
        align-self: flex-start;
        @include desktopHeading4();
      }
      img {
        width: auto;
        height: $universalSize-40;
        aspect-ratio: 2/1;
        cursor: pointer;
        margin-top: $universalSize-12;
      }
    }
    .categoriesContainer {
      display: flex;
      gap: $universalSize-28;
      align-self: center;
      padding: $universalSize-28;
      width: 100%;
      justify-content: center;
      border-top: $universalSize-1 solid $surface-light-grey;
      border-bottom: $universalSize-1 solid $surface-light-grey;
      .category {
        text-decoration: none;
        color: $blue-color-600;
        font-size: $universalSize-16;
        font-weight: $font-weight-card-heading;
        cursor: pointer;
        &.highlight {
          text-decoration: underline;
          color: $purple-color-700;
        }
        &:hover {
          opacity: 0.85;
        }
      }
      @include respond(medium) {
        display: none;
      }
    }
    @include respond(medium) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &.activeList {
    z-index: 1;
    background: $white-color;
    position: fixed;
    height: 100vh;
    .brandHeader {
      .categoriesContainer {
        display: flex;
        flex-direction: column;
        justify-content: start;
        height: 100%;
        background: $white-color;
        align-items: center;
        position: fixed;
        top: $universalSize-66;
        padding: 25% $universalSize-16 $universalSize-16 $universalSize-16;
      }
    }
  }
}
.hamBurgerContainer {
  display: none;
  @include respond(medium) {
    display: block;
    align-content: center;

    align-self: center;
    .hamBurger {
      display: flex;
      position: relative;
      width: $universalSize-24;
      height: $universalSize-2;
      margin: $universalSize-16;
      background: $black-color;
      cursor: pointer;
      transition: 0.3s;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $black-color;
        transition: 0.3s;
      }

      &::before {
        top: -($universalSize-8);
      }
      &::after {
        top: $universalSize-8;
      }

      &.active {
        background: transparent;

        &::before {
          transform: rotate(45deg);
          top: 0;
        }
        &::after {
          transform: rotate(-45deg);
          top: 0;
        }
      }
    }
  }
}
