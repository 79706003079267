@import '../../../styles/main.scss';
.videoListContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $universalSize-24;
  justify-content: space-between;
  @include respond(medium) {
    gap: 0;
  }
}

.videoListSection {
  display: flex;
  width: 85%;
  margin: 0 auto;
  flex-direction: column;
  gap: $universalSize-24;
  overflow: hidden;
}

.heading {
  font-size: $universalSize-34;
  font-weight: $font-weight-section-heading;
  line-height: $universalSize-44;
  margin: 0;

  @include respond(medium) {
    font-size: $universalSize-18;
    font-weight: $font-weight-para-heading;
    line-height: $universalSize-24;
  }
}
